@font-face {
  font-family: 'ProximanovaRegular';
  src: local('ProximanovaRegular'),
    url(./fonts/proximanova-reg.ttf) format('truetype');
}
@font-face {
  font-family: 'ProximanovaSemiBold';
  src: local('ProximanovaSemiBold'),
    url(./fonts/proximanova-sbold.ttf) format('truetype');
}

body {
  margin-top: 100px;
  font-family: 'ProximanovaRegular';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiIconButton-label{
  font-size: 14px;
  font-weight: bold;
}
.MuiIconButton-root:hover{
  background-color:transparent !important;
}